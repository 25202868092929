<template>
  <div class="pc-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item>PC网站</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="menu-list">
      <div class="menu-item" @click="toUrl('base-set')">
        <img :src="require('../../../assets/img/pc/set.png')"/>
        <div>基础设置</div>
      </div>
      <div class="menu-item" @click="toUrl('banner')">
        <img :src="require('../../../assets/img/pc/banner.png')"/>
        <div>banner设置</div>
      </div>
      <div class="menu-item" @click="toUrl('product')">
        <img :src="require('../../../assets/img/pc/product.png')"/>
        <div>产品管理</div>
      </div>
      <div class="menu-item" @click="toUrl('fw')">
        <img :src="require('../../../assets/img/pc/service.png')"/>
        <div>服务管理</div>
      </div>
      <div class="menu-item" @click="toUrl('case')">
        <img :src="require('../../../assets/img/pc/case.png')"/>
        <div>案例管理</div>
      </div>
      <div class="menu-item" @click="toUrl('news')">
        <img :src="require('../../../assets/img/pc/news.png')"/>
        <div>新闻资讯</div>
      </div>
      <div class="menu-item" @click="toUrl('download')">
        <img :src="require('../../../assets/img/pc/download.png')"/>
        <div>下载文件</div>
      </div>
      <div class="menu-item" @click="toUrl('faq')">
        <img :src="require('../../../assets/img/pc/question.png')"/>
        <div>常见问题</div>
      </div>
      <div class="menu-item" @click="toUrl('price')">
        <img :src="require('../../../assets/img/pc/price.png')"/>
        <div>产品价格</div>
      </div>
      <div class="menu-item" @click="toUrl('message')">
        <img :src="require('../../../assets/img/pc/feedback.png')"/>
        <div>留言反馈</div>
      </div>
      <div class="menu-item" @click="toUrl('service')">
        <img :src="require('../../../assets/img/pc/kefu.png')"/>
        <div>客服设置</div>
      </div>
      <div class="menu-item" @click="toUrl('customer')">
        <img :src="require('../../../assets/img/pc/hezuo.png')"/>
        <div>合作客户</div>
      </div>
      <div class="menu-item" @click="toUrl('links')">
        <img :src="require('../../../assets/img/pc/yqlj.png')"/>
        <div>友情链接</div>
      </div>
      <div class="menu-item" @click="toUrl('web-data')">
        <img :src="require('../../../assets/img/pc/data.png')"/>
        <div>网站数据</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {}
  },
  methods: {
    toUrl (url) {
      this.$router.push('/admin/apps/pc/' + url)
    }
  }
}
</script>

<style scoped>
.menu-list{
  display: flex;
  flex-wrap: wrap;
}
.menu-list .menu-item{
  width: 200px;
  background-color: #FFFFFF;
  border-radius: 6px;
  margin: 10px;
  padding: 6px;
  cursor: pointer;
}
.menu-item:hover{
  box-shadow: rgba(20, 46, 76, 0.15) 0px 48px 100px 0px;
}
.menu-list .menu-item img{
  width: 80px;
}
.menu-list .menu-item div{
  font-size: 14px;
  padding: 4px;
}
.menu-item:hover div{
  color: #142E4F;
  font-weight: bold;
}
</style>
